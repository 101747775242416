@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap'); */
/* CSS Variables*/

:root {
  /* --font-style: 'Lato', sans-serif; */
  --font-style: 'Noto Sans', sans-serif;
  /* --font-style: 'Noto Sans', sans-serif; */
  --light-black: #707070;
  --light-grey: #EAEAEA;
  --black: #242424;
  --avaya-red: #1B77AF;
  --avaya-std-red: #da291c;
  --primary-color: #1B77AF;
  /* --primary-color: #da291c; */
  --avaya-blue: #1B77AF;
  --secondary-color: #6DB5EC;
  --success-color: #63CA30;
  --warning-color: #FCAE5A;
  --error-color: #da291c;
  --text-size-base: 14px;
  --heading-color: #242424;
  --text-color: #242424;
  --text-color-secondary: rgba(16, 16, 16, .45);
  --input-background-color: rgba(165, 203, 232, 0.15);
  --text-size-x-small: 10px;
  --text-size-small: 10px;
  --text-size-medium: 14px;
  --text-size-medium-2: 12px;
  --text-size-13: 13px;
  --text-size-large: 16px;
  --text-size-18px: 18px;
  --text-size-x-large: 20px;
  --link-color: #147ecf;
  --link-disabled-color: #dddddd;
  --grey: #f5f5f5;
  --orange: #ff9e3d;
  --playfair-font: 'Playfair Display', serif;
  --approve-button-color: #039487;
  --approve-disabled-button-color: rgba(3, 148, 136, 0.395);
  --ck-z-default: 10555 !important;
  --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
}

html,
body,
a,
abbr,
address,
area,
article,
aside,
audio,
b,
base,
bdi,
bdo,
blockquote,
body,
br,
button,
canvas,
caption,
cite,
code,
col,
colgroup,
data,
datalist,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
head,
header,
hgroup,
hr,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
link,
main,
map,
mark,
meta,
meter,
nav,
noscript,
object,
ol,
optgroup,
option,
output,
p,
param,
picture,
pre,
progress,
q,
rb,
rp,
rt,
rtc,
ruby,
s,
samp,
script,
section,
select,
small,
source,
span,
strong,
style,
sub,
summary,
sup,
svg,
table,
tbody,
td,
template,
textarea,
tfoot,
th,
thead,
time,
title,
tr,
track,
u,
ul,
var,
video,
wbr {
  font-family: 'Noto Sans', sans-serif;
  font-variation-settings: "wght" 1 !important;
}

/* Common CSS */
#root {
  height: 100%;
  font-family: var(--font-style);
  font-variation-settings: "wght" 1 !important;
  color: #242424;
  overflow: hidden !important;
}

section {
  height: 100%;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #A4A4A4;
  border-radius: 10px;
}

.content {
  margin: 10px 5px 5px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
  background: white;
  border: 1px solid #C9C9C9;
  border-radius: 2px;
}

.ez-demo p {
  color: blue;
  font-size: 24px;
}

.backToTop {
  position: fixed;
  bottom: 50px;
  right: 40px;
  font-size: 24px;
  color: #DDC5C3;
  z-index: 100;
}

.red-font {
  color: var(--avaya-std-red) !important;
}

.backToTop:hover {
  color: var(--avaya-red);
}

.footer {
  background-color: F8F8F9;
  padding: 10px 20px;
}

.extra-large-text {
  font-size: var(--text-size-x-large) !important;
}

.large-text {
  font-size: var(--text-size-large) !important;
}

.medium-text {
  font-size: var(--text-size-medium);
}

.font-12 {
  font-size: var(--text-size-medium-2);
}

.small-text {
  font-size: var(--text-size-small);
}

.x-small-text {
  font-size: var(--text-size-x-small);
}

.heading {
  font-size: 18px;
  color: var(--text-color);
}

.title-heading {
  z-index: -1 !important;
  /* position: absolute; */
}

.paragraph {
  font-size: var(--text-size-base);
  color: var(--text-color);
}

.paragraph-sales {
  font-size: var(--text-size-base);
  color: var(--text-color);
  text-align: justify;
}

.label {
  color: var(--text-color);
}

.bold {
  font-weight: 600 !important;
  font-family: 'Noto Sans', sans-serif !important;
}

.semi-bold {
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
}

.thin-bold {
  font-weight: 400;
  font-family: 'Noto Sans', sans-serif;
}

.thin {
  font-weight: 200;
  font-family: 'Noto Sans', sans-serif;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100%;
}

.button-width-medium {
  width: 15%;
}

.button-width-small {
  width: 10%;
}

.text-color-primary {
  color: var(--avaya-red);
}

.text-color-white {
  color: white !important;
}

.text-color-black {
  color: black;
}

.text-color-light-grey {
  color: var(--light-black)
}

.text-color-grey {
  color: rgba(0, 0, 0, 0.45);
}

.border-gray-1 {
  border: 3px solid #e4e6e9;
}

.vertical-center-align-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.size-18px {
  font-size: var(--text-size-18px);
}

.display-none {
  display: none;
}

.italics {
  font-style: italic
}

.primary-action-button-bordered,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: transparent !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

.primary-action-button-bordered:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.primary-action-button-bordered:disabled {
  background: transparent !important;
  color: var(--link-disabled-color) !important;
  border: 1px solid var(--link-disabled-color);
}

.primary-action-button-filled,
.ant-input-search-button,
.ant-modal-confirm-btns>.ant-btn-primary {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.primary-action-button-filled:hover,
.ant-input-search-button:hover,
.ant-modal-confirm-btns>.ant-btn-primary:hover {
  background-color: #5ba7d6;
  color: white !important;
}

.primary-action-button-filled:disabled {
  background-color: var(--link-disabled-color) !important;
  color: white !important;
}

.primary-action-button-filled:focus,
.ant-input-search-button:focus,
.ant-modal-confirm-btns>.ant-btn-primary:focus {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}

.primary-action-button {
  border: none;
  background-color: white;
}

.primary-action-button:hover,
.primary-action-button:focus:hover {
  background-color: var(--primary-color);
  color: white !important;
}

.cancel-button,
.ant-modal-confirm-btns>.ant-btn:not(.ant-btn-primary) {
  background-color: var(--light-grey);
  color: var(--black);
  border: none;
}

.cancel-button:hover,
.ant-modal-confirm-btns>.ant-btn:not(.ant-btn-primary):hover {
  color: var(--black);
  background-color: var(--light-grey);
  border: none;
}

.cancel-button:focus {
  background-color: var(--light-grey);
}

.fixed-width-btn {
  width: 100%;
}

.fixed-width-btn .ant-upload {
  width: 100%;
}

.fixed-width-btn .ant-btn {
  width: 100%;
}

.right-border-radius {
  border-radius: 0 2px 2px 0;
}

.horizontal-spacing {
  margin: 0 2%;
}

.horizontal-spacing-2 {
  margin: 0 20%;
}

.vertical-spacing {
  margin: 2% 0;
}

.left-spacing {
  margin-left: 1%;
}

.right-spacing {
  margin-right: 1%;
}

.top-spacing {
  margin-top: 1%;
}

.playfair-font {
  text-overflow: ellipsis !important;
  font-family: var(--playfair-font);
}

.contactUs {
  padding-top: 15px;
}

.bottom-spacing {
  margin-bottom: 1%;
}

.bottom-spacing-2 {
  margin-bottom: 2%;
}

.bottom-spacing-3 {
  margin-bottom: 3%;
}

.left-spacing-2 {
  margin-left: 1px !important
}

.left-spacing-3 {
  margin-left: 10px !important;
}

.left-spacing-4 {
  margin-left: 42px;
}

.left-spacing-5 {
  margin-left: 15px;
}

.left-spacing-6 {
  margin-left: 10px;
}

.width-30percent {
  width: 30%
}


.margin-1 {
  margin: 5px;
}

.padding-1 {
  padding: 5px;
}

.padding-2 {
  padding: 10px;
}

.padding-left-10px {
  padding-left: 10px;
}

.padding-left-30px {
  padding-left: 30px;

}

.right-spacing-2 {
  margin-right: 10%;
}

.margin-1 {
  margin: 1%;
}

.margin-top-1 {
  margin-top: 5px !important
}

.vertical-spacing-2 {
  margin: 20px 0
}

.vertical-spacing-3 {
  margin: 8px 0
}

.table-select-status-spacing {
  margin: 2px 0
}

.top-spacing-2 {
  margin-top: 24px !important;
}

.top-spacing-3 {
  margin-top: 15px
}

.top-spacing-4 {
  margin-top: 20px;
}

.top-spacing-5 {
  margin-top: 7px;
}

.top-spacing-6 {
  margin-top: 4px;
}

.top-spacing-7 {
  margin-top: 8px;
}

.top-spacing-14 {
  margin-top: 14px;
}

.top-spacing-8 {
  margin-top: 15vh;
}

.bottom-spacing-3 {
  margin-bottom: 8px !important;
}

.baseline-flex {
  display: flex;
  align-items: baseline;
}

.no-underline {
  text-decoration: none !important;
}

.bottom-spacing-4 {
  margin-bottom: 20px;
}

.bottom-spacing-5 {
  padding-bottom: 25px;
}

.right-spacing-1 {
  margin-right: 1px;

}

.right-spacing-3 {
  margin-right: 8px;
}

.right-spacing-4 {
  margin-right: 50px;
}

.right-spacing-5 {
  margin-right: 20px;
}

.right-spacing-6 {
  margin-right: 5%;
}

.right-spacing-7 {
  margin-right: 15px;
}

.display-block {
  display: block !important;
}

.avatar-fill-color {
  background-color: var(--orange)
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.footer-row {
  padding: 10px 50px 10px 50px;
  color: grey;
}

.content-container {
  margin-top: 20px;
  padding: 10px 20px 20px 20px !important;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(16, 16, 16, .2);
}

.statusFilter-panel {
  margin: 0;
}

.content-container-support {
  padding: 25px;
  background-color: white;
  border-radius: 5px;
}

.medium-container {
  margin-top: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(16, 16, 16, .2);
}

.empty-container {
  background-color: white;
  height: 100%;
}

.active-status {
  font-size: var(--text-size-x-large);
  color: var(--success-color) !important;
}


.inactive-status {
  font-size: var(--text-size-x-large);
  color: var(--error-color) !important;
}

.font-12px {
  font-size: var(--text-size-medium-2)
}

.font-13px {
  font-size: var(--text-size-13)
}

.font-14px {
  font-size: var(--text-size-medium) !important;
  color: #242424 !important;
}

.pending-status {
  font-size: var(--text-size-x-large);
  color: var(--warning-color) !important;
}

.error-text {
  color: var(--error-color);
}

.success-text {
  color: var(--success-color);
}

.warning-text {
  color: var(--warning-color)
}

.not-tested {
  color: var(--link-color)
}

.bold-text {
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
}

.maintenance-card {
  background-color: #af706b13;
  border-right-color: #da291c28;
  border-top-color: #da291c28;
  border-bottom-color: #da291c28;

}

.demo-schedule-card {
  background-color: aliceblue;
  border-right-color: rgba(135, 207, 235, 0.409);
  border-top-color: rgba(135, 207, 235, 0.409);
  border-bottom-color: rgba(135, 207, 235, 0.409);
}

.link {
  color: var(--link-color);
  cursor: pointer;
  border: none;
  background-color: inherit;
}

.link:disabled {
  color: var(--link-disabled-color);
  cursor: not-allowed;
}

.link-disabled {
  color: var(--link-disabled-color);
  background-color: transparent;
  border: none;
  cursor: not-allowed;
}

.link-disabled>button {
  color: var(--link-disabled-color);
  background-color: transparent;
  border: none;
}

.link:focus {
  outline: none;
}

.hover-link:hover {
  color: var(--link-color);
  cursor: pointer;
}

.drag-handle {
  color: var(--primary-color);
  cursor: move !important;
}

th {
  text-align: center !important;
  font-weight: 600 !important;
  font-family: 'Noto Sans', sans-serif;
}

::-webkit-scrollbar,
::-moz-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb,
::-moz-scrollbar-thumb {
  background: #e7e7e7;
  border-radius: 10px;
}

.my-profile-desktop {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #eaeaea;
}

.my-profile-small {
  height: 10%;
  width: 100%;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.right-align {
  text-align: right !important;
}

.right-align-tag {
  font-size: 9px !important;
  line-height: 21px !important;
  margin-right: 0px !important;
  padding: 0 3px !important;
}

.left-align {
  text-align: left;
}

.medium-content {
  width: 60% !important;
}

.center-align {
  text-align: center
}

.center-vertically {
  top: 50%;
  /* transform: translateY(-50%); */
}

.long-modal {
  top: 5px !important;
  width: 60% !important;
  /* padding: 5px; */
}

.notification-banner-modal {
  top: 5% !important;
  max-width: 90%;
  min-width: 60%;
}

.capability-request-modal {
  top: 5% !important;
  bottom: 5px !important;
  width: 80% !important;
}

/* .capability-request-modal .ant-modal-content {
    padding: 0px !important;
} */

/* .capability-request-modal .ant-modal-body {
    padding-top: 0px !important;
} */

.capability-request-modal .ant-modal-header,
.capability-request-modal .ant-modal-footer {
  border: none !important;
  margin-top: 0px !important;
}

.wide-modal {
  width: 60% !important;
}

.extra-wide-modal {
  width: 80% !important;
}

.medium-modal {
  width: 50% !important;
}

.scheduler-modal {
  top: 40px !important;
  width: 95% !important;
  max-height: 65vh !important;
  /* overflow-x: hidden; */
  /* padding: 5px; */
}

.scheduler-modal-body {
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.save-report-modal {
  /* top: 5px !important; */
  width: 95% !important;
  /* padding: 5px; */
}

.collateral-modal {
  top: 2% !important;
  width: 80% !important;
}

.std-calendar-modal {
  top: 2% !important;
  width: 90% !important;
}

.cloud-calendar-modal {
  width: 90% !important;
}

.antd-button-fix {
  margin-top: 4px;
}

.antd-button-fix-2 {
  margin-top: 27px;
}

.arrow-color {
  color: var(--primary-color)
}

.arrow-bottom-spacing>svg {
  margin-bottom: 8px
}

.background-color-red {
  background-color: var(--avaya-red);
}

.text-color {
  color: var(--text-color);
}

.primary-text-color {
  color: var(--primary-color);
}

.white-panel {
  background: white;
  border-radius: 4;
  margin-bottom: 24;
  border: none;
}

.credentials {
  padding: 8px;
}

.responsive-container {
  width: 100% !important;
  overflow-x: auto
}

.left-border-secondary {
  border-left-color: var(--secondary-color) !important;
  border-left-width: thick !important;
}

.left-border-success {
  border-left-color: var(--approve-button-color) !important;
  border-left-width: thick !important;
}

.left-border-primary {
  border-left-color: var(--avaya-red);
  border-left-width: thick;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.filtered-column {
  color: #1890ff !important;
}

.medium-select .ant-select-selection--single {
  width: 175px;
  border-color: white;
  margin-left: -4%;
}

.user-email-toggle {
  width: 100% !important
}

.filter-btn {
  width: 90px
}

.filter-btn:focus {
  color: white !important;
}

/* Common antd style overrides */

​ .resource-view div {
  overflow: hidden;
}

.expander-space {
  width: 0;
}

.resource-view td {
  padding-left: 10px;
}

.resource-view span {
  display: initial;
}

.resource-view span div span {
  display: block;
  max-width: 280px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left !important;
}

#demo-card .ant-card-head-title {
  white-space: normal;
  font-size: 13px;
}

.schedule-modal.ant-modal {
  width: 400px !important;
}

.display-password.ant-input-affix-wrapper {
  width: 150px !important;
}

.display-password .ant-input:disabled {
  background-color: white !important;
}

.display-password .ant-input[disabled] {
  cursor: auto;
}

mark {
  background-color: rgb(248, 248, 113);
}

.ant-modal-body {
  /* max-height: 70vh; */
  overflow-x: none;
}

.ant-btn-primary {
  border-color: unset;
}

.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: unset;
}

.ant-btn:focus {
  color: var(--text-color);
}

.ant-tabs-ink-bar {
  background-color: var(--primary-color);
}

.ant-layout.ant-layout-has-sider>.ant-layout {
  overflow: hidden
}

.ant-layout-sider-children {
  height: 100% !important;
  margin-left: -10px;
}

.ant-alert {
  font-size: 13px;
}

.ant-layout-sider-children>ul {
  padding-top: 0%;
  max-height: 82vh;
  overflow: hidden;
  width: auto;
}


.flex-row {
  flex-direction: row;
  display: flex;
  max-height: 50px;
}

.header-avatar-title {
  font-weight: 600;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
}

.header-avatar-persona {
  max-width: 150px;
  font-size: 12px;
}

.flex-column {
  flex-direction: column;
  display: flex;
  padding-left: 5px;
}

.flex-body {
  display: flex;
  max-height: 50px;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-body div:not([class*="flex"]) {
  border: 1px solid white;
  flex: 1 1 0px;
}

.neo-gray-color {
  color: #939393
}

.ant-layout-sider-children>ul:hover,
.ant-layout-sider-children>ul:focus {
  overflow-y: auto;
  overflow-x: hidden;
}

/* .ant-menu-item-selected:hover,
.ant-menu-submenu-selected:hover {
    color: var(--primary-color) !important;
}

.ant-menu-item>a:hover,
.ant-menu-submenu-title:hover {
    color: var(--primary-color) !important;
} */

.menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #188fff27;
}


/* .ant-menu-item-selected>a,
.ant-menu-submenu-selected>a {
    color: var(--primary-color);
} */

.ant-menu-item::after {
  border-right: 4px solid var(--primary-color) !important;
}

.ant-menu-item-group-title {
  padding: 0;
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  background: var(--primary-color)
}

/* .ant-menu-submenu-selected {
    color: var(--primary-color)
} */

/* .ant-menu-vertical .ant-menu-submenu-selected {
    color: var(--primary-color)
} */

/* .ant-menu-item.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-title:hover {
    color: var(--primary-color)
} */

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none
}



/* .ant-menu-submenu-arrow:hover {
    color: var(--primary-color) !important;
} */

.ant-switch {
  width: 78px;
}

.ant-table-wrapper {
  width: 100%;
}

.ant-switch-checked:not(.expired) {
  background-color: var(--success-color) !important;
}

.ant-select {
  width: 100%;
}

.ant-fullcalendar-fullscreen .ant-select {
  width: auto;
}

.ant-form-item-label>label {
  font-size: var(--text-size-medium);
  color: var(--text-color);
}

.ant-form-item-label {
  line-height: 20px !important;
}

.form-heading {
  font-size: var(--text-size-medium);
  color: var(--text-color);
}

.ant-input-group-addon {
  padding: 0px;
}

.link-url>.ant-input-group>.ant-input-group-addon {
  padding: 5px;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--avaya-blue) !important;
}

.spin-overlay {
  background-color: white;
}

.ant-spin-dot-item {
  background-color: red;
}

.ant-select-sm {
  width: auto !important;
}

.ant-layout-header {
  background: white;
  /* padding: 0; */
  line-height: 0px !important;
}

/* .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
    background: var(--primary-color) !important;
}

.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: var(--primary-color) !important;
} */

.ant-dropdown-trigger {
  cursor: pointer;
}

.ant-table-column-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  font-family: 'Noto Sans', sans-serif;
}

.ant-select-selection-selected-value,
.ant-empty-description {
  color: var(--text-color);
}

.ant-input {
  color: var(--text-color) !important;
  background-color: var(--input-background-color);
}

.has-error .ant-input {
  border: 1px solid #f5222d;
  background-color: white;
}

.has-error .neo-input-editable__wrapper {
  border: 1px solid #f5222d !important;
  background-color: white !important;
}

.has-error .ant-select-single.ant-select-lg {
  border: 1px solid #f5222d !important;
  background-color: white !important;
}

.ant-input:disabled {
  background-color: var(--link-disabled-color) !important;
  color: var(--light-black) !important;
}

.ant-input::placeholder {
  color: var(--text-color);
  opacity: 1;
}

h1,
h2,
h3 h4,
h5,
h6,
th,
td,
p {
  color: var(--text-color) !important;
}

h1 {
  font-size: 20px !important;
}

h2 {
  font-size: 18px !important;
}

h3 {
  font-size: 15px !important;
}

h4 {
  font-size: 14px !important;
}

.ant-tabs-nav .ant-tabs-tab {
  color: var(--text-color);
}

.ant-menu-item>a,
.ant-menu-submenu {
  color: var(--text-color);
}

.ant-menu-item-selected>a,
.ant-menu-submenu-selected {
  color: var(--primary-color);
}

.ant-tag {
  white-space: unset !important;
}

.ant-tag-green {
  color: #008000;
  border-color: #008000;
  background: #E6FFCC;
}

.ant-tag-red {
  color: #DE2516;
  border-color: #DE2516;
  background: #FFF0EF;
}

.ant-tag-yellow {
  color: #FF5722;
  border-color: #FF5722;
  background: #FFFDE7;
}

/* Navigation */

.system-message {
  color: var(--text-color-secondary);
  padding: 30px;
  font-style: italic;
  font-family: 'Noto Sans', sans-serif;
}

.logo {
  width: 80px
}

#navigation-drawer .ant-drawer-body,
.ant-drawer-header {
  padding: 0;
}

.logo-text {
  display: inline-block;
  vertical-align: bottom;
}

.display-inline {
  display: inline;
}


.header-group-with-notification-banner {
  padding: 0 0px;
  /* max-height: 58px; */
  height: 107px;
}

.header-group {
  padding: 0 0px;
  /* max-height: 58px; */
  height: 58px;
}

.navigation-menu.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger {
  height: 100% !important;
  flex: 0 0 240px !important;
  width: 240px !important;
  max-width: 240px !important;
  transition: all 100ms ease-in;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #C9C9C9;
  /* display: none; */
}

.navigation-menu.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
  min-width: 60px !important;
  flex: 0 0 60px !important;
  max-width: 60px !important;
  /* display: none; */
  width: 60px !important;
  height: 100% !important;
  transition: all 430ms ease-out;
}

.navigation-menu .ant-layout-sider-trigger {
  display: none;
}

.navigation-menu .ant-layout-sider-children>ul {
  padding-top: 0% !important;
}

.menu-color {
  color: var(--primary-color)
}

.event-item>span {
  font-size: 10px !important;
}

/* Header */

.searchForm {
  width: 100%;
  transition: width 0.3s;
  background-color: #A5CBE826 !important;
}

.searchForm .ant-select-selection {
  border-radius: 0px;
  border-color: #939393;
}

/* .searchForm:focus-within,
.searchForm:hover {
    transition: width 0.1s;
    width: 100% !important;
} */

.header-search .ant-input-search-icon {
  color: var(--text-color);
}

/* HTML */

.html-container table,
.html-container table th,
.html-container table td {
  border: 1px solid var(--light-black);
}

.html-container {
  font-family: var(--font-style) !important;
}

/*LoginForm*/

.login-form {
  margin: 0 14px !important;
}

.login-form-button {
  width: 100% !important;
  margin-top: 20px;
  background-color: var(--primary-color) !important;
  ;
}

.login-form-label {
  font-size: var(--text-size-large) !important;
  color: var(--text-color);
}

.login-form-forgot-password {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

/* DemoForm */

.demo-form-submit-btn {
  position: relative;
  float: right;
}

.expired.ant-switch-checked {
  background-color: var(--primary-color);
}

.report-role-template-modal.ant-modal {
  width: 55% !important;
  top: 5px !important;
}

.std-calendar-modal.ant-modal {
  width: 90% !important;
  top: 5px !important;
}

.std-calendar-modal>.ant-modal-content {
  overflow-y: auto !important;
}

.role-template-modal .ant-modal-content {
  width: 150% !important;
}

/* DemoDetails */

.demo-details-tabs {
  margin-top: 20px;
}

/* DemoResources */

.active-group .ant-select-selection {
  background-color: #f6ffed !important;
  border: 1px solid #b7eb8f;
}

.inactive-group .ant-select-selection {
  background-color: #fffbe6 !important;
  border: 1px solid #ffe58f;
}

/* DemoResourcesTableCell */

.active-inactive-select .ant-select-selection-selected-value svg {
  display: none;
}

/* DemoStatus */

.demoStatusContainer {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* DeletedCollateralsModal */
.collateral-delete-view-table-wrapper {
  height: 50vh;
  overflow-y: auto;
}

/* MaintenanceFormContainer */

.maintenance-form-modal {
  width: 1100px !important;
  top: 80px;
}

.demo-resource-dependancyList-modal {
  width: 1000px !important;
  top: 80px;
}

.maintenance-form-modal table {
  table-layout: fixed;
  width: 150%;
}

.maintenance-form label {
  font-size: var(--text-size-base);
}

/* Manage Network */

.network-description.ant-form-item {
  margin-bottom: 0 !important;
}

.network-card {
  width: 50% !important;
}

/* News */

#news-avatar .anticon svg {
  margin-left: 8px;
}

.news-container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: white;
  box-shadow: 1px 1px 3px rgba(16, 16, 16, .2);
}

.news-article-link-text {
  font-size: 14px;
  color: var(--avaya-red) !important;
  cursor: pointer;
}


#news-nav {
  width: 300px;
  height: 485px;
  background-color: white;
  overflow-x: hidden;
  overflow-y: visible;
  text-overflow: ellipsis;
  color: var(--text-color);
}

.ant-menu-item.ant-menu-item-selected {
  color: var(--primary-color);
}


#news-nav .ant-tabs-nav-scroll {
  height: 485px !important;
  overflow: scroll !important;
}

.menu-display {
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #1B77AF;
  border-radius: 0px !important;
}

.ant-menu-item.menu-display {
  margin-top: 25px;
  padding-left: 10px !important;
}

#news-nav .ant-tabs-nav-wrap:hover,
.ant-tabs-nav-wrap:hover:focus {
  overflow-y: hidden;
}

#news-nav .ant-tabs-nav-container-scrolling {
  padding: 0px !important;
}

#news-nav .ant-tabs-tab-arrow-show {
  display: none;
}

#news-nav .ant-tabs-tab {
  padding-left: 0px;
}

#news-nav .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left;
  text-overflow: ellipsis;
}

#news-nav .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  overflow: hidden;
}

#news-nav .ant-tabs-tab-active {
  color: var(--primary-color);
  /* position: relative;
    top: 0; */
}

#news-nav .ant-avatar {
  background-color: var(--link-disabled-color)
}

.news-img {
  max-width: 100%;
  max-height: 30vh;
}

/* NewsForm */

.role-template-input {
  margin-top: 4px
}

.news-media {
  height: 200px;
}

.media-dim {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.empty-img-dim {
  height: 140px;
  width: 140px;
}

/* DemoCard */

.scrollable-tab-content {
  max-height: 380px;
  overflow-y: auto;
  padding-left: 15px;
}


.capability-content {
  padding-left: 15px;
}

.new-container {
  display: table;
  background-color: var(--primary-color);
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 5px;
  display: inline-flex;
}

.new-container span {
  vertical-align: middle;
  display: table-cell;
  color: white;
}

.glow {
  outline: none;
  box-shadow: 0 0 10px #ffc0cb;
}

.data-center-status-desc {
  background-color: var(--light-grey);
  padding: 5px 10px !important;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Support */

.wrapword {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;
  /* Chrome & Safari */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* CSS3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.feedback-note-form-item .ant-form-item-label {
  white-space: normal;
}

#support {
  height: 100% !important;
}

#supportDashboard {
  height: 100%;
}

.ant-card-body {
  color: rgb(34, 34, 34) !important
}

.scrollable-card-content-dashboard .ant-card-body {
  height: 87vh;
  overflow-y: auto;
}

.scrollable-card-content .ant-card-body {
  height: 35vh;
  overflow-y: auto;
  padding: 12px !important;
}

.scrollable-card-content .ant-card-head {
  font-size: 14px !important;
  padding: 0 12px !important;
}

.scrollable-card-content.ant-card-bordered {
  border: none;
}

.support-image-card {
  height: 100%;
  overflow-y: auto;
}

.support-image-card-dashboard {
  height: fit-content;
  display: flex;
}

.support-image-card.ant-card-bordered {
  border: none;
}

#video-list-title .ant-list-item-meta-content {
  margin-top: 2%;
}

/* UserSchedules */

.default-switch {
  width: auto !important;
}

.ant-fullcalendar-header>.ant-radio-group {
  display: none !important;
}

.ant-fullcalendar-content>.ant-tag {
  width: 100%;
}

/* DashboardNews */
/* DashboardNotifications */
.dashboard-news .ant-avatar {
  background-color: var(--link-disabled-color)
}

.dashboard-card .ant-card-body {
  height: 40vh;
  overflow-y: auto;
}

.dashboard-card-news .ant-card-body {
  height: 40vh;
  overflow-y: auto;
  padding-top: 0px !important;
  padding-left: 0px !important;
}

.dashboard-card-tip .ant-card-head {
  padding-bottom: 23.5px;
}

.support-left-card .ant-card-body {
  background-color: aliceblue;
}

.dashboard-card-trending-demos .ant-card-body {
  height: 80vh;
  overflow-y: auto;
}


.report-graph {
  height: 70vh;
  overflow-y: auto;
  width: 100%;
}

/* To be removed later */

.dashboard-card-demo .ant-card-body {
  height: 112vh;
  overflow-y: auto;
}

.dashboard-card-support .ant-card-body {
  height: 90%;
  overflow-y: auto;
}

/* To be removed later */

.dashboard-card-empty .ant-card-body {
  height: 48vh;
  overflow-y: auto;
}

/* To be removed later */
.dashboard-card-tip .ant-card-body {
  height: 40vh;
  overflow-y: auto;
}

/* To be removed later */

.dashboard-card-trending .ant-card-body {
  height: 57.4vh;
  overflow-y: auto;
}

.notification-list-item {
  padding: 12px 16px;
  padding-right: 40px;
}

.lazy-loading-card {
  overflow-y: auto;
  overflow-x: hidden !important;
  padding-right: 8px;
}

.dashboard-card-schedules .ant-card-body {
  padding-top: 10px !important;
  padding-right: 10px;
  padding-left: 15px;
  height: 100% !important;
}

.dashboard-card-requests .ant-card-body {
  padding-top: 10px !important;
  padding-right: 10px;
  padding-left: 15px;
  height: 100% !important;
}

.col-height {
  height: 100% !important;
}

.empty-dashboard-schedule {
  height: 143.4vh;
}

span.login-text {
  font-size: 15px;
  display: table;
  margin-left: auto;
  margin-right: auto;
}

/* RoleTemplatesBasicDetails */

.add-domain-btn {
  border: none;
  height: 20px;
  background-color: transparent;
}

.add-domain-btn:hover {
  background-color: transparent;
}

.new-domain-form-item {
  margin-bottom: 0;
}

/* RoleTemplatesoForm */

.role-template-form-collapse {
  background-color: white;
}

.role-template-form-collapse .ant-collapse-item {
  background-color: #fafafa;
  border-bottom: none;
}

.role-template-form-collapse .ant-collapse-content {
  background-color: white !important;
}

/* Manage Users*/


.search-div {
  padding: 8px;
  width: 205px;
}

/* Manage DIDs */

.did-table-radio-group {
  display: block !important;
  height: 30px !important;
  line-height: 30px !important;
}

.dids-pop-confirm span:hover {
  border: none !important;
}

.dids-pop-confirm .ant-popover-open {
  color: var(--error-color);
  border: 1px solid var(--error-color);
}

/* My Profile */

.cover-img-dim {
  width: 240px;
}

.user-details .ant-descriptions-item-label {
  color: black;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
}

.demo-approvers-details .ant-descriptions-item-label {
  font-weight: 600;
  font-size: 13px;
  font-family: 'Noto Sans', sans-serif;
}

.reset-default-password-form {
  color: black;
  font-size: 14px !important;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
}

.reset-default-password-form .ant-form-item-label {
  text-align: left !important;
}

.reset-default-password-form .ant-input {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-radius: 0%;
}

.reset-default-password {
  padding-left: 20px;
  margin-bottom: 0 !important;
}

.ant-list-sm .ant-list-item {
  padding-top: 0px !important;
  padding-bottom: 0px !important
}

.ant-list-item-meta-title {
  color: #000 !important;
}

.timezone-select .ant-select-selection--single {
  width: 175px;
}

.demo-credential,
div.ant-typography,
.ant-typography p {
  margin-bottom: 0 !important;
  width: 100% !important
}

/* Solutions */

.scheduler {
  position: relative;
  z-index: 0;
}

.tab-text-size {
  font-size: 15px !important;
}

.access.ant-modal {
  width: 70% !important;
}

/* home */

.carousel {
  position: relative;
}


.relative-position {
  position: relative;
}

.header {
  display: inline-block;
  margin-top: 10px;
  border-bottom: 5px solid var(--primary-color);
  text-decoration-color: var(--primary-color);
  font-weight: 600;
  font-family: var(--playfair-font);
  text-align: justify;
  font-family: 'Noto Sans', sans-serif;
}

.link-title {
  font-weight: 600;
  font-family: var(--playfair-font);
  font-size: 20px;
  font-family: 'Noto Sans', sans-serif;
}

.hoverable-text {
  cursor: pointer;
}

.home-page-image-overlay {
  position: absolute;
  top: 92px;
  left: 13%;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: #151515;
  opacity: 85%;
  width: 578px;
  height: auto;
  border-radius: 16px;
  padding: 32px 40px 32px 40px;
}

@media screen and (min-width: 1920px) {
  .home-page-image-overlay {
    left: 19.53%;
  }
}

@media screen and (max-width: 1440px) {
  .home-page-image-overlay {
    left: 9.38%;
  }
}

@media screen and (max-width: 1280px) {
  .home-page-image-overlay {
    left: 8.5%;
  }
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  gap: 43px;
  background: #FFF;
}

.sales-img-cont-2 {
  position: relative;
  left: -100px;
  display: flex;
  width: 470px;
}

.home-page-image-overlay-3 {
  /* text-align: center; */
  background-image: url("./assets/images/landing-page/catalog-4.jpg");
  background-size: 1920px 465px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 465px;
}

.contact-us-cont {
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  width: 970px;
  height: 284px;
  gap: 8px;
  box-shadow: 0px 4px 4px 0px var(--surface-transparent-black-25, rgba(0, 0, 0, 0.25));
  border-radius: 16px;
  background: white;
}

.carousel-card-title {
  font-size: 44px !important;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0.88px;
  margin-bottom: 24px;
  color: white !important;
  font-family: 'Noto Sans', sans-serif;
}

.carousel-card-intro {
  display: block;
  color: black;
  font-size: 14px;
  padding: 0px 4% 0px 4% !important;
}

.carousel-card-heading1 {
  font-size: 24px !important;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 14px;
  color: white !important;
  font-family: 'Noto Sans', sans-serif;
}

.carousel-card-subheading {
  font-size: 16px !important;
  letter-spacing: 0.32px;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 28px;
  color: white !important;
  font-family: 'Noto Sans', sans-serif;
}

.aboutus-img-dim {
  max-width: 70%;
  height: auto;
}



.content-confirmation {
  max-height: 100px;
  overflow-y: auto;
}

.view-details {
  right: 32px;
  position: absolute;
  bottom: 10px;
}

/* Responsiveness*/


/* iPad Pro */
@media only screen and (max-width: 1024px) {
  .ant-drawer-content-wrapper {
    width: auto !important;
  }

  .margin-bottom {
    margin-bottom: 24px
  }

  .heading {
    font-size: var(--text-size-small);
  }


  .news-img {
    max-width: 40vw;
  }
}


/* iPad, Tablets */
@media only screen and (max-width: 768px) {
  .ant-drawer-content-wrapper {
    width: auto !important;
  }

  #news-nav .ant-tabs-nav-wrap {
    width: 81vw;
  }

  .news-img {
    max-width: 55vw;
  }

  .responsive-container {
    width: 100% !important;
    overflow-x: auto
  }

  .medium-content {
    width: 100% !important;
  }

  /* UserSchedules */
  .ant-fullcalendar-content>.ant-tag {
    width: auto !important;
  }

  .cover-img-dim {
    width: 480px !important;
  }

  .header-search {
    width: 150px;
  }

  .network-card {
    width: 100% !important;
  }
}


/* Phone */
@media only screen and (max-width: 480px) {
  .ant-drawer-content-wrapper {
    width: auto !important;
  }

  /* UserSchedules */
  .ant-fullcalendar-calendar-body {
    width: 200% !important;
  }

  .ant-fullcalendar-fullscreen {
    overflow-x: auto;
  }

  .ant-fullcalendar-content>.ant-tag {
    width: auto !important;
  }

  #news-nav .ant-tabs .ant-tabs-left-content,
  .ant-tabs .ant-tabs-right-content {
    display: inline;
  }

  #news-nav .ant-tabs-nav-wrap {
    width: auto;
    background-color: white;
    overflow-y: auto;
    box-shadow: 1px 1px 3px rgba(16, 16, 16, .2);
  }

  #news-nav .ant-tabs-nav .ant-tabs-tab {
    padding-left: 0px
  }




  .news-img {
    max-width: 80vw;
    max-height: 20vh;
  }

  .ant-tabs .ant-tabs-left-bar {
    width: 85vw;
  }

  .header-search {
    width: 100px;
  }

  .heading {
    font-size: var(--text-size-small);
  }

  .access.ant-modal {
    width: 100% !important;
  }

  .login-form-icons {
    color: rgba(0, 0, 0, .25)
  }
}

/* IPhone 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .ant-drawer-content-wrapper {
    width: auto !important;
  }

  .header-search {
    width: 100px;
  }

  .heading {
    font-size: var(--text-size-x-small);
  }
}

/* Notification Dropdown */

.ant-dropdown {
  z-index: 1;
}



.dropdown-width {
  width: 50%;
}

.export-menu>.ant-dropdown-menu-item {
  padding: 6px 24px;
}

.ant-dropdown-menu {
  /* padding: 10px 20px; */
  overflow-y: auto;
  max-height: 70vh;
}

.ant-dropdown-menu-body {
  max-height: 60vh;
  padding-right: 10px;
  overflow-x: hidden;
}


.ant-dropdown-menu-items {
  padding: 0px
}

.notification-divider.ant-divider-horizontal {
  margin: 0 !important
}

.neo-std-divider {
  padding: 1px 0px 0px 0px;
  color: #DDDDDD;
  margin: 12px 0px;
}

.ant-scroll-number-only>p {
  color: white !important;
}

.left-border-red {
  border-left: var(--avaya-std-red) 5px solid;
}

.left-border-user {
  border-left: var(--secondary-color) 5px solid;
  height: 10%;
}

.left-border-admin {
  border-left: #ffcc00ac 5px solid;
}

.ant-notification-notice {
  max-height: 90vh;
  overflow: auto;
}

.ant-notification {
  z-index: 800;
}

.unsubscribe-modal {
  width: 50% !important;
}

.notification.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  padding: 0;
}

.timezone-profile-page {
  padding-bottom: 0px !important;
  padding-top: 0px;
}

.demo-card-body {
  margin-bottom: 15px !important;
}

.demo-card-body-maintenance {
  padding-bottom: 15px;
  margin-bottom: 15px;
  ;
}

.demo-card-timings {
  font-family: var(--font-style) !important;
  font-size: "13px"
}

.demo-card-body-details {
  font-size: 13px;
  /* overflow-y: auto; */
}

.reports-card-body-details {
  font-size: 13px;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.notification-banner-dashboard .ant-carousel,
.notification-banner-dashboard-nodots .ant-carousel {
  text-align: center;
  height: 48px !important;
  line-height: 40px;
  overflow: hidden;
  background: #FFD79B;
  /* margin-bottom: 2% 0; */
  /* border-radius: 4px; */
  /* border-bottom: 1px solid #d9d9d9; */
  box-sizing: border-box;
  /* display: flex; */
  /* justify-content: center; */
}

.notification-banner-dashboard .ant-carousel .slick-list,
.notification-banner-dashboard-nodots .ant-carousel .slick-list {
  height: 48px;
  display: grid;
  align-items: start;
}

.notification-banner-dashboard .ant-carousel .slick-track,
.notification-banner-dashboard-nodots .ant-carousel .slick-track {
  height: 48px;
}

.notification-banner-title {
  width: 100% !important;
  display: flex;
}

.notification-banner-title>h3 {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

}

/* .notification-banner-dashboard .ant-carousel .slick-dots li button {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: lightsalmon
}

.notification-banner-dashboard-nodots .ant-carousel .slick-dots li button {
    display: none;
}

.notification-banner-dashboard .ant-carousel .slick-dots li.slick-active button {
    background: rgb(218, 41, 28);
} */

.notification-banner-dashboard .ant-carousel .slick-dots-bottom {
  bottom: 20px;
}

.notification-banner-content {
  padding: 15px;
}

.notification-banner-dashboard .ant-carousel .slick-prev,
.notification-banner-dashboard-nodots .ant-carousel .slick-prev {
  left: 30px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  z-index: 1;
}

.notification-banner-dashboard .ant-carousel .slick-next,
.notification-banner-dashboard-nodots .ant-carousel .slick-next {
  right: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.notification-banner-dashboard .ant-carousel .slick-prev,
.notification-banner-dashboard-nodots .ant-carousel .slick-prev,
.ant-carousel .slick-next {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.subheading {
  font-size: 12px;
  color: var(--avaya-red) !important;
  cursor: pointer;
}

.schedule-view-title {
  font-size: 13px;
  margin: 10%;
  cursor: pointer;
  color: #474747;
  text-align: center;
}

.supportLinks {
  color: black;
}

.schedules-table-view-button {
  padding-bottom: 5px;
  margin-right: 80px;
  float: right;
}

.schedule-table-tab .ant-tabs-bar {
  margin: 0px;
}

.tab-layout .ant-tabs-bar {
  margin: 0px;
}

.arrow-style {
  z-index: 1;
  border: solid rgb(187, 184, 184);
  border-width: 0 3px 3px 0;
  padding: 3px;
  height: 17px;
  width: 17px;
  position: absolute;
  top: 30px
}

.arrow-left {
  left: 30px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow-right {
  right: 30px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.ant-modal {
  z-index: 999999999 !important;
}

.ant-dropdown {
  z-index: 100;
}

.ant-breadcrumb>span:last-child {
  color: rgba(0, 0, 0, 0.45);
}

#display-flex {
  display: flex;
}

.col-white-background {
  background: white;
  height: 100% !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.contactCard {
  padding: 0px !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
}

.border-white-background {
  border-bottom-style: solid;
  border-bottom-color: white !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-card-body .margin-bottom-dashboard {
  margin-bottom: 22px;
}

.dashboard-card-grid-height {
  height: 100% !important;
  border: 3px 3px 3px 3px solid #e4e6e9;
  background: white;

}

.col-background-gray {
  /* background: white; */
  background: #f0f2f5;
}

.dashboard-row-cards {
  /* border: 3px solid #e4e6e9; */
  border-radius: 6px;
}

.vertical-spacing-treding-demo {
  padding: 1% 0;
  margin-left: 23px;
  /* background: white; */
  background: #f0f2f5;
  border-bottom: 0.5px solid lightgrey;
  border-top: 0.5px solid lightgray;
}

/* User Section - Reports */

.report-card-col {
  margin-bottom: 10px;
}

.report-card-actions {
  color: black;
  font-size: 13px;

}

.report-menu .ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a {
  width: 100% !important;
  font-family: var(--font-style) !important;
  color: var(--link-color);
}

.report-menu {
  width: 100% !important;
  font-family: var(--font-style) !important;
  color: var(--link-color);
  padding: 10px;
}

.spin-align-center {
  margin: 0;
  position: absolute;
  top: 25%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
}

.z-index {
  z-index: 1;
  position: relative;
}

.report-description {
  padding-left: 10px;
  padding-right: 24px;
  display: contents;
}

.custom-report-description {
  padding-left: 24px;
  padding-right: 24px;
}

.divider-margin {
  margin: 0px 0px 24px 0px;
}

.thick-border {
  border-top: 3px solid #E8E8E8;
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody>tr>td {
  word-wrap: break-word;
  /* word-break: break-all; */
}

thead[class*="ant-table-thead"] th {
  word-break: keep-all;
}

.report-drag-select>div {
  cursor: pointer;
  line-height: 10px;
  margin-top: 4px;
  border-radius: 2px !important;
  border-color: #939393;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-tlfecz-indicatorContainer {
  display: none !important;
}

.entitySelect .ant-select-selection--single {
  height: 38px !important;
}

.report-drag-select>div:focus-within {
  cursor: pointer;
  line-height: 10px;
  margin-top: 4px;
  border: lightblue
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.entitySelect .ant-select-selection-selected-value {
  margin-top: 4px;
}

/* .heightSelect {
    max-height: 600px !important;
    height: 100% !important;
} */

/* .heightSelect .rc-virtual-list-holder {
    max-height: 600px !important;
    height: auto !important;

} */

.text-align-last {
  text-align-last: center;
}

.searchCheckBox {
  margin-bottom: 7px;
  margin-right: 5px;
}

.approval-refresh {
  padding-left: 10px !important
}

.approval-details-modal {
  top: 2% !important;
  width: 90% !important;
}

.approval-steps {
  padding: 20px;
}

.approve-action-button-filled {
  background-color: var(--approve-button-color);
  color: white;
  border-radius: 4px !important;
}

.approve-action-button-filled:hover {
  background-color: rgb(21, 175, 95);
  color: white !important;
}

.approve-action-button-filled:disabled {
  background-color: var(--approve-disabled-button-color) !important;
  color: white !important;
}

.approve-action-button-filled:focus {
  background-color: var(--approve-button-color) !important;
  color: white !important;
  border: none !important;
}

.demoApprovedStatus {
  margin-top: 8px;
}

.methodType {
  width: 100px;
}

.fullWidth {
  width: 100%;
}

.maintenance-card-demo-list {
  height: auto;
  max-height: 40vh;
  overflow-y: scroll;
  width: auto;
}

.region-height {
  height: 200px;
  overflow: auto;
}

.builds-status {
  color: white !important;
}

.beautify {
  color: var(--secondary-color);
  cursor: pointer;
  float: left;
}

.ant-tag-gold {
  color: #cc8a06;
  background: #fffbe6;
  border-color: #c98302;
}

.center-align-content {
  display: flex;
  justify-content: center;
}

.right-align-content {
  display: flex;
  justify-content: right;
}

.ant-menu-item {
  color: black;
}

.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  color: #1B77AF;
  font-size: 19px !important;
}

.menuLinks {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  font-weight: normal;
  font-family: 'Noto Sans', sans-serif;
}


.bell-icon-spacing {
  margin-top: -10px;
  padding-left: 35px !important;
}

.search-bar-group-items {
  float: right !important;
  z-index: 10;
}

.portal-menu-heading {
  width: 100% !important;
  z-index: 2;
  position: absolute !important;
}

.login-button-home {
  margin: 10px;
  float: right !important;
}

.review-Rating {
  padding-left: 10px;
  padding-right: 10px;
}

.rating-split-up {
  min-width: 100px;
  max-width: 1000px;
  max-height: 700px;
  border: grey;
}

.rating-count {
  padding-right: 15px;
  text-align: center;
  margin-bottom: -2px;
}

.rating-average {
  text-align: center;
  font-size: 40px;
}

.dashboard-feedback-form {
  width: 1000px !important;
  top: 80px;
}

.rating-demo-card {
  border: none;
  background: inherit;
}

.reviews-form-modal {
  top: 20px !important;
  width: 95% !important;
  padding: 5px;
}

.demo-reviews-author-header .ant-collapse-borderless {
  background-color: inherit;
  border: 0;
  margin-left: -19px;
}

.demo-reviews-author-header .reviews-section-panel .ant-collapse>.ant-collapse-item {
  border-bottom: 0px
}

.demo-reviews-author-header .reviews-section-panel .ant-collapse-borderless>.ant-collapse-item {
  border-bottom: 0px
}

.reviews-section-panel .ant-collapse-borderless {
  background-color: inherit;
  border: 0;
  margin-left: -19px;
}

.reviews-section-panel .ant-collapse>.ant-collapse-item {
  border-bottom: 0px
}

.reviews-section-panel .ant-collapse-borderless>.ant-collapse-item {
  border-bottom: 0px
}

.reviews-panel-header {
  margin-right: 1%;
  margin-top: 3px;
}

.comments-action-button-disabled {
  color: var(--link-disabled-color) !important;
  cursor: not-allowed !important;
}

#solution-demo-card-status .ant-popover-inner-content {
  width: 500px !important;
}

#buildStatusInfo .ant-popover-inner-content {
  max-height: 90vh !important;
  overflow: auto !important;
  min-width: 30vw;
}


.demo-status-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard-demo-status-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.rbc-current-time-indicator {
  background-color: red;
}

.rbc-overlay {
  max-height: 80%;
  overflow: auto;
  z-index: 999;
}

.maintenance-steps-content {
  margin-top: 2em;
}

.access-alert .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-left: 0px !important;
}

.vertical-align {
  vertical-align: top;
}

.vertical-center-align {
  margin: 0;
  position: absolute;
  top: 50%;
}

.ant-typography-copy,
.anticon .anticon-copy {
  color: black !important;
}

.border-notification {
  display: inline;
  text-shadow: 0 0 3px white;
}

.build-comments {
  height: 200px;
  overflow-y: auto;
}

.ant-select-dropdown-menu-item {
  white-space: unset !important;
}

.demo-registration-form-header {
  margin-bottom: 30px;
  font-size: 18px;
  color: black !important;
  text-decoration: underline;
}

.demo-registration-form-body {
  border: 1px solid rgb(233, 232, 232);
  padding: 25px;
  border-radius: 1px;
  margin-top: 20px;
}

.demo-registration-form-body .ant-row.ant-form-item {
  margin-bottom: 20px !important;
  border-bottom: 0.5px solid rgb(233, 232, 232);
  border-bottom-style: dotted;
}

.calendar-scheduler {
  height: 500px;
  width: auto;
  overflow: scroll;
}

.drawer-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  text-align: right;
}

.text-color-white,
.text-color-white:hover {
  color: white;
  background: var(--avaya-red);
}

.resource-header {
  margin-top: 10px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  height: 50px;
  display: grid;
  align-items: center;
  overflow-x: scroll;
}

.empty {
  visibility: hidden;
  overflow: none;
}

.overflow-none {
  overflow: hidden;
  height: 200px;
}

.rbc-time-gutter>.rbc-timeslot-group>.rbc-time-slot>.rbc-label {
  text-align: center;
  position: sticky;
}



.question-answer-details .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  opacity: 50%;
}

.question-answer-details .ant-radio-checked .ant-radio-inner {
  background-color: #1890ff;
  opacity: 50%;
}

.requested-button {
  background-color: var(--link-disabled-color);
  color: white !important;
}

.label-request {
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
}

.ant-row.ant-form-item {
  margin-bottom: 0px !important;
}

.dashboardMenu .ant-menu-horizontal .ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
  border-bottom: 2px solid var(--avaya-red) !important;
  color: var(--avaya-red) !important;
}

.btn-status:active,
.btn-status:focus {
  color: white;
}

.fixed-title {
  z-index: -1;
  padding: 0.2em;
  background-color: rgb(255 255 255);
  top: 0%;
  transform: translateZ(0);
  padding-bottom: 5px;
  padding-top: 15px;
  /* position: sticky !important; */
  font-size: 16px;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
}

.lock-text,
.lock-text:hover,
.lock-text:focus {
  background-color: var(--avaya-red);
  color: white;
  font-size: 25px;
  border-radius: 50%;
  position: absolute;
  margin-top: 200px;
  right: 0px !important;
  z-index: 30;
  margin-right: -14px;
  font-size: 30px;
  cursor: pointer;
}

.unlock-lock-text,
.unlock-lock-text:hover,
.unlock-lock-text:focus {
  background-color: var(--avaya-red);
  color: white;
  font-size: 25px;
  border-radius: 50%;
  position: absolute;
  margin-top: 200px;
  right: 0px !important;
  z-index: 30;
  margin-right: -14px;
  font-size: 30px;
  cursor: pointer;
}

/* 
.button-lock,
.button-lock:hover,
.button-lock:focus {
    margin-top: 1px;
    justify-content: flex-end !important;
    align-items: center;
    display: flex;
  } */


.delete-option-button {
  width: 70%;
  margin: 10px;
}

.delete-option-button-email {
  width: 60%;
  margin: 10px;
}

.delete-option-modal {
  text-align: center;
}

.delete-cancel-button {
  width: 90%;
}

.delete-cancel-button-email {
  width: 90%;
}

.schedule-delete-button {
  border-radius: 5px;
  background: #f7f3f3;
}

.no-underline,
.no-underline:hover {
  text-decoration: none !important;
  border: none;
  color: black;
  padding-left: 0px !important;
  width: 100%;
  display: inline-flex;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>.ant-btn.no-underline {
  color: var(--avaya-red) !important;
}

.no-data .neo-icon-info {
  font-size: 20px !important;
  color: rgb(145, 150, 154) !important;
  margin-right: 5px;
}

.build-email-template-table {
  width: 100%;
}

.email-recipients-form .ant-input {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-radius: 0%;
}

.email-recipients-form .ant-select-selection {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-radius: 0%;
}


.email-recipients-form.ant-input:focus {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-color: #000;
  border: 0px solid white;
  outline: none;
}

.email-recipients-form .ant-form-item-label>label {
  font-size: 14px !important;
}

.select_dropdown {
  display: none !important;
}


.email-summary-body {
  border-radius: 0.5px;
  border-style: solid;
  border-color: #d3d3d3;
  margin: 3%;
  padding: 3%;
  background-color: #f2f2f2;
  /* overflow: scroll ;max-height: 200px; width: 100%; */
}

.ant-upload-hint {
  font-size: 10px !important;
  margin: 0 0 4px !important;
}

.ant-upload-text {
  margin: 0 0 4px !important;
  font-size: 14px !important;
}

/* 

Mobiscroll Styling starts here

*/

.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: var(--avaya-red) !important;
}

.mbsc-ios.mbsc-schedule-time-indicator {
  border-color: var(--avaya-red) !important;
}

.mbsc-ios.mbsc-schedule-time-indicator-time {
  background: hsla(0, 0%, 100%, .8);
  color: var(--avaya-red) !important;
}

.md-custom-range-view-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.mbsc-material .mbsc-calendar-title {
  font-size: 1.428572em;
  font-weight: 400;
  text-transform: none;
  line-height: 1.4em;
  font-family: 'Noto Sans', sans-serif;

}

.mbsc-timeline-header-time {
  padding: 0 .5em;
  font-size: .625em;
  min-width: 0;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2.6em;
  color: black;
  font-family: 'Noto Sans', sans-serif;
}

.md-event-listing-picker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobiscroll-header-dropdown {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
}

.mobiscroll-header-dropdown>div {
  flex: 1;
  margin: 0 10px;
  /* Adjust margin as needed */
}


.md-resource-header-template-name {
  height: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.md-resource-details .mbsc-timeline-resource,
.md-resource-details .mbsc-timeline-resource-col {
  width: 250px;
}

.scheduler-actions-buttons {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}

.calendar-prev::after {
  content: "Prev";
  margin-right: 20px;
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
}

.mbsc-calendar-button.calendar-prev.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat.mbsc-icon-button {
  width: 70px;
  position: relative;
  margin-left: -35px;
}

.mbsc-calendar-button.calendar-next.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat.mbsc-icon-button {
  width: 70px;
}

.calendar-next::before {
  content: "Next";
  margin-left: 18px;
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
}

.calendar-today {
  margin-right: 15px;
}

.mbsc-calendar-button.calendar-next.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat.mbsc-icon-button:focus {
  background-color: rgb(219 212 212 / 80%);
}

.mbsc-calendar-button.calendar-prev.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat.mbsc-icon-button:focus {
  background-color: rgb(219 212 212 / 80%);
}

.mbsc-calendar-button.mbsc-calendar-button-today.mbsc-reset.mbsc-font.mbsc-button.mbsc-ios.mbsc-ltr.mbsc-button-flat:focus {
  background-color: rgb(219 212 212 / 80%);
}

/* 

Mobiscroll Styling ends here

*/
.mbsc-event-list.mbsc-event-list-scroll {
  height: 500px !important;
}

.mbsc-windows.mbsc-calendar-width-md .mbsc-calendar-title {
  font-size: 17px;
  font-weight: 500 !important;
  font-family: 'Noto Sans', sans-serif;
  line-height: 1.461538em;
  padding: 0 .307693em;
}

.custom-event-popover.mbsc-material .mbsc-popover-list .mbsc-event {
  padding: 10px 14px;
}

.custom-event-popover.mbsc-ios .mbsc-popover-list {
  width: 390px;
}

.custom-event-popover.mbsc-material .mbsc-popover-list {
  width: 320px;
}

.custom-event-popover.mbsc-windows .mbsc-popover-list {
  width: 340px;
}

.md-custom-event-cont {
  display: flex;
  align-items: center;
  padding-top: 10px;
  font-size: 13px;
}

.mbsc-windows.mbsc-event-color {
  width: 0.2em !important;
  align-self: stretch !important;
}

.multi-day-event {
  padding-left: 5px;
  font-size: 12px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  border-radius: 1px;
  font-family: 'Noto Sans', sans-serif;
}

.clickable {
  cursor: pointer;
}

.filter-panel-container {
  margin-top: 10px;
  padding: 10px 10px 1px 10px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  border-radius: 5px;
}

.tabbed-layout {
  /* margin-bottom: 12px; */
  /* position: sticky; */
  background-color: #f0f2f5;
  width: -moz-available;
  z-index: 10;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
}

.ant-spin-dot-item {
  background-color: var(--avaya-red);
}

/* Working */
.tabbed-layout>.ant-tabs-bar {
  background: #f0f2f5;
  position: fixed;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  /* border-bottom: 1px solid rgb(201, 201, 201) !important; */
  z-index: 999 !important;
  /* border-bottom: 0px solid #e8e8e8 */
  /* 

    position: sticky;
    top: 0;
    background: #f0f2f5; */

}


.tabbed-layout>.ant-tabs-bar .ant-tabs-tab {

  background-color: white !important;
  /* border-radius: 3px 3px 0px 0px !important; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px, rgba(0, 0, 0, 0.1) 0px 0px 15px 0px; */
  /* border-top-left-radius: 5px !important; */
  /* border-top-right-radius: 5px !important; */
  /* border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important; */
  background: #fafcfe !important;
}

.tabbed-layout>.ant-tabs-card-bar .ant-tabs-nav-container {
  margin-left: 5px;
  /* border-left: 1px solid #C9C9C9 !important; */
}


.tabbed-layout>.ant-tabs-bar .ant-tabs-tab {
  margin-top: 5px !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
  background: #f0f2f5 !important;
  border-radius: 0px 0px 0px 0px !important;
  border-left: 0px solid #f0f2f5 !important;
  border-right: 1px solid #f0f2f5 !important;
  border-top: 1px solid #f0f2f5 !important;
  /* /* border-top: 1px solid #e81a0b !important; */
  border-bottom: 0px solid white !important;
}

.tabbed-layout>.ant-tabs-bar .ant-tabs-tab-active {
  background: white !important;
  font-weight: 600 !important;
  border-radius: 1px solid !important;
  color: #1B77AF !important;
  border-right: 1px solid #f0f2f5 !important;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  border: 1px solid rgb(201, 201, 201) !important;
  z-index: 999;
}



.tabbed-layout>.ant-tabs-bar .ant-tabs-close-x {
  color: #313131 !important;
}

.tabbed-layout>.ant-tabs-bar .ant-tabs-extra-content {
  margin-right: 10px !important;
  /* border-bottom: 1px solid #e81a0b !important; */
  /* box-shadow: 0 10px 6px -6px #9da0a4; */
  display: flex;
  margin-top: -1px;
}

.tabbed-layout>.ant-tabs-bar .ant-tabs-tab-active .ant-tabs-close-x {
  color: #1B77AF !important;
}


#container {
  margin-top: 20px;
}

.component-status-bar {
  padding-left: 10px !important;
  position: fixed;
  z-index: 10;
  background-color: #f0f2f5;
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: stretch;
  padding-top: 10px;
}

/* .ant-table-footer{
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
} */

.positioning {
  margin-top: -10px;
  padding-bottom: 15px;
  margin-right: 12px;
}

.component-container {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -9px;

}


/* .pane-content{
    height: 72vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 15px;
} */

.content::-webkit-scrollbar-thumb {
  background-color: #f1f1f1 !important
}

.ant-table-thead,
.ant-table-tbody {
  background: white !important;
}

.tab-title {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.solution-align {
  display: flex;
  flex-direction: row-reverse;
}

.default-text {
  color: var(--link-color);
}

.daily-drawer-title {
  display: flex;
  justify-content: center;
  margin: 13px;
}

.daily-report-drawer-table {
  font-weight: 300px;
  margin-left: 5px !important;
  font-family: 'Noto Sans', sans-serif;
}

.margin-ten {
  margin: 10px;
}

.two-columns {
  columns: 2 auto;
}

.get-started {
  margin: 3%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 8%;
}

.content-cover {
  background-image: url('../src/assets/images/landing-page.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin-top: -5%;
  background-color: transparent;
}

.get-started-greeting {
  text-align: center;
  font-size: large;
  font-weight: 600;
  padding: 20px;
  font-family: 'Noto Sans', sans-serif;
}

.recently-opened-tabs {
  color: black;
  text-align: center;
}

.get-started-search .ant-select-selection {
  /* top-left | top-right | bottom-right | bottom-left */
  /* border-radius: 25px 50px 30px 25px; */
  border-color: cornflowerblue;
  /* top | right | bottom | left */
  /* border-width: 2px 0px 2px 2px; */
}

.get-started-search {
  padding: 25px;
  /* width: 150%; */
}

.get-started-search-height {
  height: 250px !important;
}

.get-started-search-height .ant-select-dropdown-menu {
  max-height: 250px !important;
}

.get-started-search-row {
  width: 80%;
}

.tab-limit-setting-form>.ant-form-item .ant-form-item-label {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

.tab-limit-select {
  width: 130% !important;
}

.go-back-button {
  display: flex;
  flex-direction: column;
  align-items: unset;
}

.flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 35px !important;
}

.glow2 {
  outline: none;
  box-shadow: 0 0 1px #65a2ebc0;
}

.glow-float-button {
  outline: none;
  box-shadow: 0 0 10px #4676a9;
}

.button-float-shadow {
  box-shadow: 0 0 10px #4676a9
}

.glow2:hover {
  outline: none;
  /* box-shadow: 1px 1px 1px #65a2ebc0; */
  background: #effbffcd;
  transform: translateY(-1px) scale(1.006) translateZ(-1.2px);
}


.ez-demo-card {
  font-size: 14px;
  color: #000;
  box-shadow: 0 0 2px #90b6e5c0;
}

.ez-demo-card .ant-card-body {
  padding: 12px 12px 12px 0px;
}

/* .card-div {
    background: conic-gradient(rgb(251, 251, 198), rgb(250, 218, 212));
    color: white;
}

.card-div:hover {
    background: conic-gradient(rgb(250, 218, 212), rgb(251, 251, 198));
} */

.glow3 {
  outline: none;
  box-shadow: 0 0 3px #ffabb9;
}

.glow3:hover {
  outline: none;
  background: #fff6f8;
  box-shadow: 0 0 8px #ffabb9;
  transform: translateY(-1px) scale(1.001) translateZ(0);
}

.capability-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: black;
  margin-bottom: 10px;
  margin-Top: 10px;
  gap: 20px;
}

.notification-margin {
  margin-top: 5px;
}

.demo-form-approvers>.ant-card-body {
  padding: 10px !important;
}

.input-borderless {
  border: none !important;
  width: fit-content;
  pointer-events: none;
}

.input-borderless:hover {
  background: aliceblue;
}

.scheduler-alert.ant-alert-info {
  border: none !important;
  width: fit-content !important;
}

.capability-alert.ant-alert-info {
  border: none !important;
}

.capability-tooltip .ant-tooltip-inner {
  min-width: 50px !important;
  width: 300px !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  color: #a83737;
}

.ckeditor-readonly {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #dddddded;
  resize: none;
}

.email-templates-category-div {
  width: 250px;
  overflow: scroll !important;
  height: 650px;
}

.feedback-modal-title {
  margin: auto;
  display: table;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 15px;
  color: #000;
  font-family: 'Noto Sans', sans-serif;
}

.feedback-icon {
  opacity: 50%;
}

.news-link {
  font-size: 12px;
  color: var(--link-color) !important;
  cursor: pointer;
}

body>iframe {
  display: none;
}

.menu-icons {
  font-size: 18px;
}

.header-background {
  position: absolute;
  width: 80px;
  height: 58px;
  left: -20px;
  background: #DA291C;
  transform: skew(25deg);

}

.header-image {
  position: absolute;
  width: 26.87px;
  height: 22.92px;
  left: 30px;
  top: calc(50% - 22.92px/2 + 0.3px);
  transform: skew(-25deg);
}

.ant-badge-multiple-words {
  padding: 0 2px !important;
}


.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: rotate(90deg);
}

.ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  transform: rotate(180deg);
}

.footer-neo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 9px;
}

.footer-neo-collapsed {
  position: absolute;
  /* bottom: 35px; */
  margin-left: 10px;
}

.footer-statement {
  margin: 0 10px 0 10px;
  color: #000 !important;
  font-size: 13px !important;
}

.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
.ant-menu-light>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
  color: var(--avaya-blue)
}

.collapsible-icon {
  cursor: pointer;
  font-size: 20px;
  margin-left: 220px;
}

.collapsible-icon-collapsed {
  cursor: pointer;
  font-size: 20px;
  margin-left: 20px;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 38px !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 38px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* border-left: 7.8px solid var(--avaya-red);
    padding-left: 16px !important; */
  border-radius: 0px !important;
}

.ant-menu-item::after {
  border-right: 4px solid #ffffff !important;
}

.additional-support-menu-0 {
  border: 0.5px solid #ebebeb;
  border-bottom: none;
  border-right: none;
  border-radius: 0px !important;
}

.additional-support-menu-1 {
  border: 1px solid #C9C9C9;
  border-top: none;
  border-radius: 0px !important;
}

.super-admin-settings {
  border: 0.5px solid #ebebeb;
  border-top: none;
  border-right: none;
  border-radius: 0px !important;
}

.dashboard-card-news .ant-card-head {
  padding: 0px !important;
  border-bottom: 0px !important;
  border-radius: 0px !important;
}

.login-button {
  position: absolute;
  right: 10px;
  top: 12px;
}

.neo-heading-style {
  font-size: 15px;
  color: black;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
}

.ant-table-body {
  font-size: 13px;
}

.ant-list-lg .ant-list-item {
  padding: 10px 0px;
}

.solution-name-title {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.demo-menu-title {
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #1B77AF !important;
  white-space: normal !important;
  font-family: 'Noto Sans', sans-serif;
}

.demo-card-title {
  font-family: Noto Sans;
  font-size: 26px !important;
  font-weight: 400 !important;
  line-height: 40px !important;
  text-align: left;
  color: #1B77AF;
}

.reports-menu-title {
  font-family: Noto Sans;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #1B77AF;
}

.news-menu-title {
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #1B77AF !important;
  white-space: normal !important;
  font-family: 'Noto Sans', sans-serif;
}

.news-menu-title-2 {
  font-family: Noto Sans;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #242424 !important;

}

.news-time-info {
  font-family: Noto Sans;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #5E5E5E !important;
}

.demo-ratings {
  font-size: 12px;
  line-height: 16px;
  color: #5E5E5E;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
}

.demo-menu.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: auto !important;
  font-family: 'Noto Sans', sans-serif;
}

.news-menu.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: auto !important;
  margin-top: 10px !important;
}

.menu.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  margin-top: 0px !important;
}

.demo-rating-star.ant-rate {
  color: orange;
  font-size: 12px;
  margin-left: 10px;
}

.demo-rating-star>.ant-rate-star:not(:last-child) {
  margin-right: -3px !important;
}

.menu-demo-container {
  margin-top: 13px;
}

.demo-menu:where(.css-dev-only-do-not-override-w8mnev).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
:where(.css-dev-only-do-not-override-w8mnev).ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  border-radius: 0px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.demo-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f1f1f1;
}

.demo-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>.ant-btn.no-underline {
  color: black !important;
}

.gray-col-background {
  background: #F1F1F1;
}

.demo-details-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  height: auto;
}

.demo-type-details {
  font-size: 12px;
  color: black;
}

.user-solution-type {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #242424;

}

.font {
  font-family: 'Noto Sans', sans-serif;
}

.green-tag {
  background-color: #E4F7E4;
  border: 1.3px solid #51A651;
}

.orange-tag {
  background-color: #FFF7EB;
  border: 1.3px solid #F38D00;
}

.red-tag {
  background-color: #FDEDED;
  border: 1.3px solid #FA6156;
}

.maintenance-tag {
  background-color: #E7F4FB;
  border: 1.3px solid #1CC6D7;
}

.new-tag {
  background-color: #E7F4FB;
  border: 1.3px solid #2E9FDB;
}

.updated-tag {
  border: 1.3px solid #BF73E5;
  background-color: #F7EDFC;
}

.green-tag-color {
  color: #51A651 !important;
}

.orange-tag-color {
  color: #F38D00 !important;
}

.red-tag-color {
  color: #FA6156 !important;
}

.maintenance-tag-color {
  color: #1CC6D7 !important;

}

.icon-action {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 20px;
}

.demo-tab-pane {
  background-color: white !important;
  margin: 0 5px;
  padding: 0 20px;
}

.demo-tab-pane .demo-tabs>.ant-tabs-bar .ant-tabs-tab {
  background-color: white !important;
  background: #fafcfe !important;
}

.demo-tabs>.ant-tabs-card-bar .ant-tabs-nav-container {
  margin-left: 5px;
}

.demo-tabs>.ant-tabs-bar .ant-tabs-tab {
  background: #f0f2f5 !important;
  border-radius: 0px 0px 0px 0px !important;
  border-left: 0px solid #f0f2f5 !important;
  border-right: 1px solid #f0f2f5 !important;
  border-top: 1px solid #f0f2f5 !important;
  border-bottom: 0px solid white !important;
}

.demo-tabs>.ant-tabs-bar .ant-tabs-tab-active {
  background: white !important;
  font-weight: 600 !important;
  border-radius: 1px solid !important;
  color: #1B77AF !important;
  border-right: 1px solid #f0f2f5 !important;
  z-index: 1000;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
}

.demo-tabs .ant-tabs-bar {
  margin: 0px !important;
}

.neo-empty-state {
  color: var(--empty-state-color);
  margin: 2%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  position: relative !important;
  top: 0 !important;
  left: 0;
  right: 0;
  transform: translateY(0%) !important;
  font-family: 'Noto Sans', sans-serif;
}

/* body .neo-multiselect__content.neo-set-keyboard-focus,
body .neo-multiselect__content:focus-visible {
    outline-offset: 0px !important;
    outline: 1px auto transparent !important;
}

body :focus-visible,
body .neo-set-keyboard-focus {
    outline-offset: 0px !important;
}

.neo-multiselect:focus .neo-multiselect-combo__header,
.neo-multiselect:focus-within .neo-multiselect-combo__header {
    border-color: black !important;
    border-width: 1px !important;
    margin-top: -2px
} */

.active-news-radio {
  color: white;
  border-radius: 0px !important;
  margin-bottom: 10px;
}

.primary-action-button-bordered,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff !important;
  background: #1B77AF !important;
  border-color: none !important;
  border: 0px solid white;
}

.ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:last-child {
  border-radius: 0px !important;
}

/* .ant-radio-button-wrapper:not(:first-child)::before {
    top: 0 !important;
    left: 0px !important;
    width: 0.7px !important;
    height: 94% !important
} */

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #1B77AF;
  /* Change to your desired hover border color */
  /* box-shadow: 0 0 3px rgba(24, 144, 255, 0.5); */
  /* Change to your desired hover box shadow */
}

.ant-select-single .ant-select-selector {
  border-radius: 0px !important;
}

.radio-box-news {
  margin-left: 5px;
}

.display-none {
  display: none !important;
}

.footer-class {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.secondary-button {
  background: #ff0000c4 !important;
  color: white !important;
  border-color: red !important;
}

:root {
  --main-color: rgb(48, 64, 80);
  --point-color: #ecf0f1;
  --size: 5px;
}

.loader {
  background-color: var(--main-color);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 100000;
}

.loader__element {
  border-radius: 100%;
  border: var(--size) solid var(--point-color);
  margin: calc(var(--size)*2);
}

.loader__element:nth-child(1) {
  animation: preloader .6s ease-in-out alternate infinite;
}

.loader__element:nth-child(2) {
  animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
  100% {
    transform: scale(2);
  }
}

.ant-popover,
.ant-popover-content,
.ant-popover-inner-content,
.ant-modal-content {
  font-family: var(--font-style) !important;
}



/* Notification item */

/* Notification item */
.ant-notification-notice {
  border-radius: 3px !important;
  /* Adjust the value as needed */
  padding: 20px !important;
  z-index: 1001 !important;
  margin-bottom: 0px !important;
}

.action-notification .ant-notification-notice-message {
  display: none;
}

.action-notification .ant-notification-notice-close {
  display: none !important;
}

.center-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.ez-demos-menu-item {
  font-size: 16px;
  font-weight: 600;
  /* line-height: 24px !important; */
  letter-spacing: 0em;
  text-align: left;
  color: #1B77AF !important;
  padding: 0px, 8px, 8px, 0px !important;
}

.ez-demo-menu {
  height: 300px;
  overflow-y: auto;
}

.ez-demo-date {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.ez-demo-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1B77AF !important;
}

.ez-demo-divider {
  color: #DDDDDD;
  margin: 12px 0;
  height: 0.5px !important;
}

.login-modal .ant-modal-header {
  border-bottom: 0px !important;
}

.login-modal .ant-modal-title {
  font-size: 19px !important;
  font-weight: 400 !important;
}

.login-form-icons {
  font-size: 17px !important;
  color: #323232 !important;
}

.demo-request-questions {
  margin-top: 30px;
  padding: 16px;
  border: 1px solid #C9C9C9;
}

/* .wrapper {
    width: 200px;
    height: 60px;
    position: relative;
    z-index: 1;
}

.circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    left: 15%;
    transform-origin: 50%;
    animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0%;
    }
}

.circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: .7;
    }

    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
}

.shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
} */





.loader-3 {
  width: 10em;
  display: flex;
  justify-content: space-evenly;
}

.circle {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
}

.circle:nth-child(1) {
  background-color: #ffffff29;
}

.circle:nth-child(2) {
  background-color: #ffffff53;
}

.circle:nth-child(3) {
  background-color: #ffffff8c;
}

.circle:nth-child(4) {
  background-color: #ffffffd1;
}

.circle:nth-child(5) {
  background-color: #ffffff;
}

.circle::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  animation: animateLoader38 2s ease-out infinite;
}

.circle:nth-child(1)::before {
  background-color: #ffffff29;
}

.circle:nth-child(2)::before {
  background-color: #ffffff53;
  animation-delay: 0.2s;
}

.circle:nth-child(3)::before {
  background-color: #ffffff8c;
  animation-delay: 0.4s;
}

.circle:nth-child(4)::before {
  background-color: #ffffffd1;
  animation-delay: 0.6s;
}

.circle:nth-child(5)::before {
  background-color: #ffffff;
  animation-delay: 0.8s;
}

@keyframes animateLoader38 {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}


.loader {
  display: block;
  width: 12em;
  height: 12em;
  overflow: visible;
}

.loader path.fill {
  fill: #2Af2;
  animation: fill 4s ease-in-out infinite;
}

.loader .dash path {
  stroke: #2AF;
  stroke-width: 1px;
  stroke-linecap: round;
  animation: dashArray var(--sped, 2s) ease-in-out infinite,
    dashOffset var(--sped, 2s) linear infinite;
}

.loader .dash path.aaa {
  stroke-width: 2px;
  stroke-linecap: butt;
  clip-path: path('M 20.4603 48.5493 L 16.6461 46.9584 C 17.3209 48.3794 18.4917 49.5682 20.0447 50.2206 C 23.4007 51.6328 27.2707 50.0262 28.6694 46.6367 C 29.3464 44.9966 29.3509 43.1867 28.6806 41.5422 C 28.0103 39.8977 26.7434 38.6151 25.119 37.9315 C 23.5035 37.2544 21.7741 37.279 20.2547 37.8576 L 24.1961 39.5022 C 26.6719 40.5434 27.8427 43.4124 26.8104 45.9105 C 25.7803 48.4085 22.936 49.5905 20.4603 48.5493 Z');
}

.loader .dash path.big {
  stroke-width: 2px;
  filter: drop-shadow(0 0 2px #2AF);
}

@keyframes dashArray {
  0% {
    stroke-dasharray: 0 1 359 0;
  }

  50% {
    stroke-dasharray: 0 359 1 0;
  }

  100% {
    stroke-dasharray: 359 1 0 0;
  }
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: -5;
  }

  100% {
    stroke-dashoffset: -365;
  }
}

@keyframes fill {

  30%,
  55% {
    fill: #2AF0;
  }
}

.readonly-element {
  pointer-events: none;
  user-select: none;
  cursor: default;
  margin: 5px;
}

.full-width-options {
  width: 100% !important;
  display: block;
}

.approvals-divider.ant-divider-horizontal {
  margin: 12px 0 !important;
  color: #000 !important;
}

.demo-request-modal {
  top: 5% !important;
  bottom: 5px !important;
  width: 80% !important;
}


.demo-request-modal .ant-modal-body {
  padding-top: 0px !important;
  max-height: 450px;
  overflow-x: none;
  overflow-y: scroll;
  min-height: 250px;
}

.demo-request-modal .ant-modal-header,
.demo-request-modal .ant-modal-footer {
  border: none !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.reports-items {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-right: 10px !important;
}

.reports-menu {
  height: 65vh;
  overflow-y: auto;
}

.viewText {
  font-weight: 500 !important;
  margin-right: 20px;
  font-size: 12px;
  letter-spacing: 0em;
  cursor: pointer;
  color: #1B77AF;
}

.view-more-link {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 12px;
}

.request-dropdown-menu {
  color: #147ecf;
  font-size: 13px;
  padding: 5px 12px !important;
}

.dropdown-menu-requests.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  padding: 5px 5px !important;
}

.schedule-demo-details {
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #C9C9C9;
}

.center-align-cell {
  text-align: center;
}

.dashboard-action-dropdown .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content {
  margin: 4px 8px !important;
}

.resource-override {
  pointer-events: all;
  cursor: pointer;
  color: #1B77AF;
}

.neo-form .neo-form-control .neo-switch:only-child {
  margin-top: 8px !important;
}

.neo-form .neo-form-control--textarea {
  width: 98% !important;
}

.disabled-text-input {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.refine-report-border {
  border-radius: var(--Border-Radius-Semantic-Panel, 4px);
  border: 1px solid var(--Base-200, #C9C9C9);
  background: var(--Base-0, #FFF);
  padding: 16px;
  gap: 16px;
}

.refine-report-filter {
  border-radius: var(--Border-Radius-Semantic-Panel, 4px);
  background: var(--Base-100, #F1F1F1);
  padding: 16px;
  margin-top: 8px;
  gap: 16px;

}

.info-neo-notification>.ant-popover-content {
  border: 1px solid #088a08 !important;
  border-left-width: 0.275rem !important;
  background-color: #e4f7e47f;
}

.info-neo-notification .ant-popover-arrow {
  /* border: 1px solid #088a08 !important;
    border-top: none !important;
    border-left: none !important; */
  background-color: white !important;
}

.fill-icon-increase {
  color: #008000 !important;
}

.fill-icon-decrease {
  color: #DA291C !important;
}

#bold-title {
  font-weight: 600 !important;
  padding: 0px !important;
}

.neo-alert-notification>.neo-notification.neo-notification--alert {
  width: 50%;
}

.neo-alert-notification>.neo-notification.neo-notification--alert>.neo-icon-end {
  display: none !important;
}

/* .header-support-icon-button {
    padding: 0px !important;
} */
/* .header-support-icon-button {
    padding: 0px !important;
} */

/*ckeditor 5*/

@media print {
  .ck.ck-toolbar__items {
    display: none !important;
  }
}


.ck.ck-content {
  font-family: "PT Serif", serif;
  font-size: 16px;
  line-height: 1.6;
  padding: 2em;
}

.ck-content .ck-horizontal-line {
  margin-bottom: 1em;
}

.ck.ck-content hr {
  width: 100px;
  border-top: 1px solid #aaa;
  height: 1px;
  margin: 1em auto;
}

.ck.ck-content h3.category {
  font-family: "Bebas Neue";
  font-size: 20px;
  font-weight: bold;
  color: #d1d1d1;
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}

.ck.ck-content h2.document-title {
  font-family: "Bebas Neue";
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  border: 0;
}

.ck.ck-content h3.document-subtitle {
  font-size: 20px;
  color: #e91e63;
  margin: 0 0 1em;
  font-weight: normal;
  padding: 0;
}

.ck.ck-content p.info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(135deg,
      var(--background-color) 0%,
      var(--background-color) var(--background-size),
      transparent var(--background-size)),
    linear-gradient(135deg,
      transparent calc(100% - var(--background-size)),
      var(--background-color) calc(100% - var(--background-size)),
      var(--background-color));
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}

.ck.ck-content blockquote.side-quote {
  font-family: "Bebas Neue";
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}

.ck.ck-content blockquote.side-quote::before {
  content: "“";
  position: absolute;
  top: -37px;
  left: -10px;
  display: block;
  font-size: 200px;
  color: #e7e7e7;
  z-index: -1;
  line-height: 1;
}

.ck.ck-content blockquote.side-quote p {
  font-size: 2em;
  line-height: 1;
}

.ck.ck-content blockquote.side-quote p:last-child:not(:first-child) {
  font-size: 1.3em;
  text-align: right;
  color: #555;
}

.ck.ck-content span.marker {
  background: yellow;
}

.ck.ck-content span.spoiler {
  background: #000;
  color: #000;
}

.ck.ck-content span.spoiler:hover {
  background: #000;
  color: #fff;
}

.ck.ck-content pre.fancy-code {
  border: 0;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 10px;
}

.ck.ck-content pre.fancy-code::before {
  content: "";
  display: block;
  height: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
  margin-bottom: 8px;
  background-repeat: no-repeat;
}

.ck.ck-content pre.fancy-code-dark {
  background: #272822;
  color: #fff;
  box-shadow: 5px 5px 0 #0000001f;
}

.ck.ck-content pre.fancy-code-bright {
  background: #dddfe0;
  color: #000;
  box-shadow: 5px 5px 0 #b3b3b3;
}

.ck-editor__editable {
  width: 100%;
  max-height: 275px;
  height: 275px;
  overflow: auto;
  resize: vertical;
  font-size: 14px !important;
  box-sizing: border-box;
  z-index: 1 !important;
  /* Adjust as needed */
  padding: 0 1.5rem !important;
}

.ck-editor__editable>dl,
.ck-editor__editable>ol,
.ck-editor__editable>ul {
  margin: auto 2rem !important;
}

.ck.ck-toolbar {
  border-bottom: 1px solid #d1d1d1 !important;
  background: #f8f8f8 !important;
  padding: 6px 8px 2px !important;
  white-space: normal !important;
}

.ck.ck-reset.ck-editor {
  z-index: 1 !important;
}

.ck.ck-dropdown__panel {
  max-height: 200px !important;
  min-height: 100px !important;
  overflow-y: auto !important;
  z-index: 9999 !important;
  box-sizing: border-box !important;
}

.ck.ck-content hr {
  width: 100% !important;
}

figure.image img {
  max-width: 100%;
}

.height-neo-table {
  min-height: 250px;
  padding-bottom: 160px;
}

.ck-content table {
  border-collapse: collapse;
  width: 100%;
}

.ck-content th,
.ck-content td {
  border: 1px solid #ccc;
  padding: 8px;
}